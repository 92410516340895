import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Iffert Media | Social Media Marketing & Advertising', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description:
    "Iffert Media is a social media marketing agency in Nashville working with clients nationwide! Let's grow your business together.",
  image: 'https://www.iffertmedia.com/IFFERT-MEDIA.png',
  siteUrl: 'https://www.iffertmedia.com/', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Social Media Marketing & Advertising',
  name: 'Iffert Media',
  subtitle: "Let's grow your business together",
  cta: 'Services',
};

// ABOUT DATA
export const aboutData = {
  img: 'aboutiffertmedia.jpg',
  paragraphOne:
    "Every business wants more leads that convert to sales. But how do you attain that goal? That's where we come in. Iffert Media is a digital marketing agency located in Nashville, TN and was founded to help turn businesses into money making machines with social media marketing and advertising.",
  paragraphTwo:
    "As an agency, we've worked with solopreneurs like health coaches, wedding professionals, personal trainers, and real estate agents to small-medium businesses like gyms and restaurants. Our portfolio also includes national accounts such as fitness supplements and other e-commerce brands.",
  paragraphThree:
    'We create a winning marketing plan specific to your business. We then execute the plan to increase ROI and conversion rates, while reaching your business goals.',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/iffertmedia/',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/iffertmedia/',
    },
  ],
};
